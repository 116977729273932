@import url('./Fonts/index.scss');

$primary-color: #F05F23;
$secondary-color: #000D23;
$white-color: #FFFFFF;

body {
  margin: 0;
  padding: 0;
  font-family: 'Satoshi-Variable', sans-serif;
}

* {
  font-family: 'Satoshi-Variable', sans-serif !important;
}

.App {
  width: calc(100vw - 2rem);
  height: calc(100vh - 1rem);
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
  &.isMobile {
    // height: -webkit-fill-available;
    // height: fill-available;
    // height: -moz-available;
    height: calc(calc(var(--vh, 1vh) * 100) - 1rem);
    padding-bottom: 1rem;
  }
}

.Header {
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem;
  min-height: 3rem;
  &__Logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    gap: 0.5rem;
    h1 {
      margin: 0;
      padding: 0;
      font-size: 1.25rem;
    }
  }
  &__Menu {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex: 1;
    ul {
      display: flex;
      flex: 1;
      list-style: none;
      margin: 0;
      padding: 0;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__CTO {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .Button {
      max-width: 12rem;
    }
  }
}

main {
  padding: 1rem;
}

.Banner {
  flex: 1;
  border-radius: 1.5rem;
  background-color: $secondary-color;
  display: flex;
  flex-direction: column;
  color: #FFF;
  justify-content: center;
  align-items: center;
  &__Background {
    z-index: 1 !important;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    opacity: 0.30;
    width: calc(100vw - 2rem);
    height: calc(100vh - 1rem - 3rem - 2rem);
    object-fit: cover;
    border-radius: 1.5rem;
    .isMobile & {
      height: calc(calc(var(--vh, 1vh) * 100) - 1rem - 6rem);
    }
  }
  * {
    z-index: 2;
    margin: 0;
    padding: 0;
  }
  &__Title {
    display: flex;
    flex-direction: column-reverse;
    h3 {
      font-variant: small-caps;
      text-align: center;
      text-transform: lowercase;
      font-weight: 300;
      .isMobile & {
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }
    h2 {
      font-family: 'Satoshi-Regular';
      text-align: center;
      font-size: 3rem;
      font-weight: 300;
      max-width: 32rem;
      .isMobile & {
        max-width: 100vw;
        font-size: 1.75rem;
      }
      strong {
        font-family: 'Satoshi-Bold';
      }
    }
  }
  &__Form {
    margin-top: 2rem;
    max-width: 32rem;
    .isMobile & {
      max-width: 100vw;
    }
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h4 {
      margin: 0;
      padding: 0;
      font-weight: 300;
      font-size: 2rem;
      text-align: center;
    }
    h5 {
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 1rem;
      text-align: center;
    }
    p {
      text-align: center;
    }
    &__InputGroup {
      background: #FFF;
      min-height: 2.5rem;
      padding: 0rem 0.5rem;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      .isMobile & {
        margin: 0rem 1rem;
        min-height: 3.5rem;
        gap: 1rem;
      }
      label {
        display: flex;
        align-items: center;
      }
      input {
        border: 0;
        margin: 0;
        padding: 0;
        font-family: 'Satoshi', sans-serif;
        flex: 1;
        font-size: 1rem;
      }
      &__AccessoryLeft {
        color: $secondary-color;
      }
    }
    &__Buttons {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      gap: 1rem;
      .isMobile & {
        flex-direction: column;
        margin: 0rem 1rem;
        margin-top: 1rem;
      }
    }
  }
}

button,
.Button{
  background: $primary-color;
  color: #FFF;
  min-height: 48px;
  border: 0;
  border-radius: 0.33rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-family: 'Satoshi', sans-serif;
  font-size: 1rem;
  padding: 0rem 1rem;
  transition: all 300ms ease;
  cursor: pointer;
  &__Outlined {
    color: $primary-color;
    background: none;
    border: 1px solid $primary-color;
  }
  &__Outlined__Light {
    color: $white-color;
    background: none;
    border: 1px solid $white-color;
  }
  &__Hover {
    transition: all ease 300ms;
  }
  &__Hovered {
    cursor: not-allowed;
  }
}

// Animations
.fade-enter {
  opacity: 0;
  transform: translateY(1px);
}

.fade-exit {
  opacity: 1;
  transform: translateY(-1rem);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0rem);
}

.fade-exit-active {
  transform: translateY(-1rem);
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: all ease 300ms;
}

.button-container {
  transition: all ease 300ms;
}

// @media only screen and (max-width: 768px) {
//   .Banner {
//     &__Title {
//       h3 {
//         font-size: 1rem;
//       }
//     }
//   } 
// }