.hide {
  display: none;
}

.Icon {
  &__Spinner {
    animation: rotateSpinner 800ms linear infinite;
  }
}

@keyframes rotateSpinner {
  to {
    transform: rotate(360deg);
  }
}